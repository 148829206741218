.box {
    max-width: 250px;
    min-height: 100px;
    padding: 8px;
    cursor: pointer;
    margin-bottom: 4px;
    flex-grow: 1;
}

.order-done-state {
    background-color: lightgray !important;
}

.order-minor-state {
    background-color: #f0ad4e !important;
}

.order-major-state {
    background-color: #d9534f !important;
}

.order-driverstarted-state {
    background-color: lightskyblue !important;
}

.order-drivercompleted-state {
    background-color: dodgerblue !important;
}

.order-selected {
    border: solid 8px green !important;
}

.listItemBox {
    padding: 8px;
    cursor: pointer;
    margin-bottom: 4px;
}

.panel {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.box:hover, .listItemBox:hover {
    background-color: lightgray;
}

.container {
    width: 250px;
}

.small {
    font-size: x-small;
    text-transform: uppercase;
    line-height: 8px;
    font-weight: bold;
}

.orderDate {
    font-size: small;
    line-height: 8px;
    font-weight: bolder;
}

.smallListItem {
    font-size: small;
    line-height: 16px;
}

.remarks {
    font-size: x-small;
    text-transform: uppercase;
    line-height: 12px;
}

.header {
    font-size: medium;
    overflow: hidden;
    text-transform: uppercase;
}