.group{
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    /* align-items: center; */
    gap: 8px;
}

.groupRow{
    
}

.mainCard{
    width: 100%;
    height: 50rem;
}

.card{
    width: 20rem;
}

.contactPersonsList{
    display: flex;
    flex-direction: row;
}

@media print {
    .pageBreak {
        font-size: x-small;
        padding: 20px;
        display: block;
        page-break-before: auto;
    }

    .printHide {
        display: none !important;
    }

    .printShow {
        display: block !important;
    }
}