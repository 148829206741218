// // Import Bootstrap
// @import "~bootstrap/scss/bootstrap";

// // Zmienne kolorów
// $btn-bg: #ff0000;  // Kolor główny
// $bg-color: #f4f4f4; // Kolor tła
// $theme-colors: (
//     "primary": #198754,
//     "success": #198754,

//   "test": #900,
// "bg-color": #f4f4f4
// );
$bg-test: #bc0606;

$primary: #198754;
$success: #0d6efd;
// $bg-color: #f4f4f4;

$theme-colors: (
    "primary": #198754,
    "success": #0d6efd,
    "fiolet": rgb(153, 0, 148),
    "secondary": #6c757d,
    "pink": #e83e8c,
    "orange": #fd7e14,
    "indigo": #6610f2,
    "teal": #20c997,
    "cyan": #17a2b8,
    "danger": #bc0606,
    "warning": #ffc107,
    "navy": #074173,
    "lightblue": #87CEFAFF,
    "dodgerblue": #1E90FFFF
);

// // Nadpisanie zmiennych kolorów Bootstrapa
// $theme-colors: (
//   "primary": $btn-bg,
//   "background": $bg-color,
//   // Dodatkowe zmienne kolorów Bootstrapa, jeśli chcesz
// );

// Importowanie reszty Bootstrapa po dostosowaniach
@import "~bootstrap/scss/bootstrap";
