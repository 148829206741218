.selectedOptions{
    display: flex;
    flex-direction: row;
    gap: 2px;
    margin-bottom: 4px;
    text-transform: uppercase;
}

.options{
    position: absolute;
    overflow: auto;
    height: 150px;
    border: 1px solid lightgray;
    border-radius: 8px;
    z-index: 3;
    width: 100%;
    background-color: white;
}

.option{
    cursor: pointer;
    font-size: small;
    text-transform: uppercase !important;
}

.option:hover{
    background-color: #198754;
    color: white;
}


.mainContainer{
    position: relative;
    width: auto;
}

.singleSelect{
    display: flex;
    position: relative;
    align-items: center;
}

.actions{
    position: absolute;
    right: 36px;
    top: 16px;
    display: flex;
    gap: 8px;
}

.searcher{
    position: relative;
    display: flex;
    align-items: center;
}

.searcherIcon{
    position: absolute;
    right: 16px;
    top: 12px;
}