@media print {
    .pageBreak {
        font-size: x-small;
        padding: 20px;
        display: block;
        page-break-before: auto;
    }

    .printHide {
        display: none !important;
    }
}
