.app-container {
  min-height: 350px;
  width: 100%;
  font-size: small;
}

*{
  box-sizing: border-box;
}

.modal-md {
  width: 900px !important;
}

.hover-pointer:hover {
  cursor: pointer;
}

.hover-grey:hover {
  background-color: lightgray;
}

.dark-grey {
  background-color: gray !important;
}

.light-grey {
  background-color: lightgray !important;
}

.white-text {
  color: white !important;
}

.form-check-input, .form-check-label {
  cursor: pointer;
}

.domain-control {
  font-size: small !important;
}

.bdo-form {
  font-size: large !important;
}

.x-small {
  font-size: 12px !important;
}

.small-spinner{
  width: 11px !important;
  height: 11px !important;
}

.modal-md > .modal-content {
  width: 650px !important;
}

.modal-xl > .modal-content {
  width: 1200px !important;

}

.error {
  color: #dd2d3d
}

.error-bg {
  background-color: #dd2d3d !important
}

.warning-bg {
  background-color: #ffc107 !important
}

.form-input {
  text-transform: uppercase;
  font-size: small !important;
}

.form-border {
  border: solid 1px #dee2e6;
  border-radius: 0.375rem;
}

.login-input {
  text-transform: none !important;
}

td {
  text-transform: uppercase;
}

.upper {
  text-transform: uppercase;
}

.react-datetime-picker__wrapper{
  border: none;
}

.react-datetime-picker__calendar, .react-datetime-picker__clock {
  z-index: 3;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.td-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.td-ellipsis::after {
  content: attr(data-full-text);
  position: absolute;
  background: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  white-space: normal;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
  bottom: 100%; /* Pokaż tooltip nad tekstem */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.td-ellipsis:hover::after {
  opacity: 1;
  visibility: visible;
}